import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';
import { countBy, forIn, max, min, sum, uniq } from 'lodash';

import * as defaults from '../utils/defaults';
import { rhythm } from '../utils/typography';
import Layout from '../components/layout';
import {
  HistogramChart,
  BarChart,
  ScatterChart,
  StyledSVG,
} from '../components/Charts';

// import static_data from '../../data/Food_Establishment_Inspection_Scores.csv'

/* Article Design Concept
 *
 * - URL
 * - Data ETL
 *   - Range
 *   - Scale
 *   - Labels
 * - Controls
 * - Styling
 *
 * HELPFUL URL
 * - https://www.robinwieruch.de/react-fetching-data/
 * - https://www.robinwieruch.de/react-with-graphql-tutorial/
 * */

class AustinFoodScores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loaded_data: false,
      record_requests: 0,
      records_per_request: 1000,
    };
  }

  componentWillMount() {
    this.getData();
  }

  componentDidMount() {
    this.setState({ record_requests: 1000 });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.data === nextState.data) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.setState({
      data: null,
      loaded_data: false,
      record_requests: 0,
      records_per_request: 1000,
    });
  }

  // will need to look into methods for alternative code loading
  // could be a thing that graphql handles well
  // isJson(data) {
  //   if (data.json()) return data.json();
  // }
  async getData() {
    await fetch(
      `${this.props.dataUri}?$order=inspection_date&$offset=${
        this.state.record_requests
      }&$limit=${this.state.records_per_request}`,
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong ...');
      })
      .then(data => {
        if (this.state.data === null) {
          this.setState({
            data: [...data],
            loaded_data: true,
          });
        } else {
          this.setState(prevState => ({
            data: [...prevState.data, ...data],
          }));
        }
      });
  }

  getMoreData() {
    const requests = this.state.record_requests;
    this.setState({
      record_requests: requests + this.state.records_per_request,
    });
    this.getData();
  }

  distribution() {
    const collection = [];
    const scores = this.state.data.map(record => this.extractScore(record));
    const mean = sum(scores) / scores.length;
    scores.map(i => collection.push((i - mean) ** 2));

    const variance = sum(collection) / (collection.length - 1);
    const standardDeviation = Math.sqrt(variance);

    return { standardDeviation, variance };
  }

  mostInspectedEstablishments(data) {
    let { inspectionsPerLocation, names } = [];

    names = data.map(record => this.extractName(record));
    const namesCount = countBy(names);
    const minCount = min(Object.values(namesCount));
    const maxCount = max(Object.values(namesCount));
    inspectionsPerLocation = [];
    forIn(namesCount, (count, name) =>
      inspectionsPerLocation.push({
        x: Math.floor(Math.random() * this.props.sizing.width),
        y: Math.floor(Math.random() * this.props.sizing.height),
        r: Math.floor(((count - minCount + 1) / (maxCount - minCount)) * 20),
        label: `${name}:${count}`,
      }),
    );
    return { names, inspectionsPerLocation };
  }

  // makeCircles(data) {
  //   const chartData = [];
  //   forIn(data, d =>
  //     chartData.push({
  //       x: this.humanTime(+d.inspection_date),
  //       y: +d.score,
  //       r: count / this.props.width,
  //       label: `${d.restaurant_name} ${d.score} ${this.humanTime(
  //         +d.inspection_date,
  //       )}`,
  //     }),
  //   );
  //   return chartData;
  // }

  aggregateScoresByZip(data) {
    const scores = data.map(record => this.extractScore(record));
    const zips = data.map(record => this.extractZipCodeData(record));

    // [{score: 95, zip: "78717"}]
    const scoresByZip = scores.map((value, index) => ({
      x: zips[index],
      label: zips[index],
      y: value,
    })); // this depends on the order of data :(

    // {78613: 11, 78617: 3, 78620: 1, 78652: 6, 78653: 6, ...}
    const countPerZip = countBy(zips);
    const inspectionsPerZip = [];
    forIn(countPerZip, (inspectionCount, zip) =>
      inspectionsPerZip.push({
        x: zip,
        y: inspectionCount,
        label: zip,
      }),
    );
    return {
      countPerZip,
      scores,
      scoresByZip,
      inspectionsPerZip,
      zips,
    };
  }

  humanTime(input) {
    return new Date(parseFloat(input) * 1000);
  }

  extractZipCodeData(record) {
    // return JSON.parse(record.address.human_address).zip;
    if (!!record.address) {
     return JSON.parse(record.address.human_address).zip 
   }
   return "unknown"
  }

  extractName(record) {
    return record.restaurant_name;
  }

  extractScore(record) {
    // prefix + to cast string to int
    return +record.score;
  }

  render() {
    let chart;
    if (this.state.loaded_data) {
      const { data } = this.state;
      const {
        inspectionsPerZip,
        scores,
        zips,
        scoresByZip,
      } = this.aggregateScoresByZip(data);
      const {
        names,
        inspectionsPerLocation,
      } = this.mostInspectedEstablishments(data);
      const lowestScore = min(scores);
      const uniqueZips = uniq(zips);

      // I want to eventually pass scale and color to the chart
      // const x = d3.scaleLinear()
      //              .domain(d3.extent(scores)).nice()
      //              .range([50, 500]);

      chart = (
        <div>
          <p>
            You are viewing&nbsp;
            {data.length}
            {' '}
records
          </p>
          <p>
            There are&nbsp;
            {uniqueZips.length}
            {' '}
zip codes in this set
          </p>
          <p>
            There are 
            {' '}
            {uniq(names).length}
            {' '}
unique establishments in this set
          </p>
          <p>
            The lowest score is&nbsp;
            {lowestScore}
          </p>
          <button onClick={() => this.getMoreData()}>
            Would you like more data?
          </button>
          <h4>Inspection Count per Zip</h4>
          <StyledSVG width="100%" height={480}>
            <BarChart
              data={inspectionsPerZip}
              xLabel="zip code"
              yLabel="# of inspections"
            />
          </StyledSVG>
          <h4>Inspections per establishment</h4>
          <p>A visualization of the count with randomized positioning</p>
          <StyledSVG>
            <ScatterChart
              data={inspectionsPerLocation}
              colors={this.props.colors}
              sizing={{
                width: 1000,
                height: 480,
                margin: { top: 0, right: 0, bottom: 0, left: 0 },
              }}
            />
          </StyledSVG>
          <h4>Score distribution across set</h4>
          <HistogramChart
            data={scores}
            colors={this.props.colors}
            sizing={this.props.sizing}
            xLabel="scores"
            yLabel="# of inspections"
          />
          {/*
          <h4>Inspection Count per Zip</h4>
          <StyledSVG width="100%" height={480}>
            <BarChart
              data={scoresByZip}
              xLabel="zip code"
              yLabel="# of inspections"
            />
          </StyledSVG>

          */}
        </div>
      );
    } else {
      chart = 'loading...';
    }
    return (
      <Layout location={this.props.location}>
        <section
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(0)}`,
          }}
        >
          {chart}
        </section>
      </Layout>
    );
  }
}

AustinFoodScores.propTypes = {
  colors: PropTypes.object,
  dataUri: PropTypes.string,
  location: PropTypes.object,
  sizing: PropTypes.object,
};

AustinFoodScores.defaultProps = {
  // dataUri: 'https://data.austintexas.gov/resource/nguv-n54k.json',
  dataUri: 'https://data.austintexas.gov/resource/ecmv-9xxi.json',
  sizing: defaults.sizing,
};

export default AustinFoodScores;
